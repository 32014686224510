import React from "react";
import { Row, Col, Typography } from "antd";
import { Link } from "gatsby";
import Layout from "../../components/Layout/layout";
import SEO from "../../components/seo";
import CtaSecondary from "../../components/Buttons/secondary-cta";
import ogImage from "../../images/global_assets/og-image.png";
import LegalStyles from "../../components/Layout/LegalStyles";

const { Title } = Typography;

const GBP = () => {
  return (
    <Layout className="consumer-privacy">
      <SEO
        title="SpotOn | Privacy &amp; Legal"
        description="SPOTON TRANSACT LLC Google Business Profile & Google Ads TERMS OF SERVICE"
        image={`https://spoton.com/${ogImage}`}
      />
      <div style={{ marginTop: 25 }}>
        <CtaSecondary target="/legal" ctaTitle="Legal" reverseIcon />
      </div>
      <main className="consumer-privacy__data">
        <Row>
          <Col lg={16} sm={24}>
            <div>
              <Title level={5}>EFFECTIVE AS OF MARCH 17TH, 2023</Title>
              <Title level={1}>
                SPOTON TRANSACT LLC Google Business Profile & Google Ads TERMS
                OF SERVICE
              </Title>
              <p>
                These SpotOn Transact LLC (“SpotOn”) Google Business Profile
                (“GBP”) & Google Ads Terms of Service (“GBP Terms”), outline the
                terms regarding Client’s use of a SpotOn account for GBP &
                Google Ads Services.
              </p>
              <p>
                These GBP Terms are incorporated into, made part of, and
                governed by the Merchant Terms and Conditions for Services
                Provided by SpotOn Transact, LLC:{" "}
                <a href="https://www.google.com/intl/en_us/ex/13100835/business/faq/?mid=13100835">
                  (“Merchant Terms”)
                </a>
                . If there is a conflict between the Merchant Terms and the GBP
                Terms, the GBP Terms control for the GBP & Google Ads Services.
              </p>
              <p>
                These GBP Terms are a legally binding contract between Client
                and SpotOn. If Client does not agree with the GBP Terms, do not
                use SpotOn for GBP Services.
              </p>
              <p>
                By using SpotOn for GBP & Google Ads Services, Client agrees to
                be bound by the GBP Terms.
              </p>
              <p>
                If Client uses SpotOn for GBP & Google Ads Services on behalf of
                an entity, Client agrees to the GBP Terms for that entity and
                represent and warrant to SpotOn that the individual agreeing for
                Client has the authority to bind that entity to the GBP Terms.
              </p>
              <Title level={2}>1. CHANGES TO GBP TERMS</Title>
              <p>
                SpotOn reserves the right to modify the GBP Terms. SpotOn will
                post the most current version of the GBP Terms at:{" "}
                <Link to="/legal/gmb-terms">
                  www.spoton.com/legal/gmb-terms
                </Link>
                . Client’s continued use of GBP Services after SpotOn publishes
                or notifies Client about SpotOn’s changes to the GBP Terms means
                Client consents to the updated terms.
              </p>
              <Title level={2}>2. GBP SERVICES</Title>
              <p>
                GBP & Google Ads allows Client to promote its Business Profile
                and website on Google Search and Maps to connect with Client’s
                customers, post updates to its Business Profile (e.g. address,
                telephone, website, open hours, photos, special offers,
                promotions), and see how its customers interact with its
                business on Google. GBP Services FAQ:{" "}
                <a href="https://www.google.com/intl/en_us/ex/13100835/business/faq/?mid=13100835">
                  https://www.google.com/intl/en_us/ex/13100835/business/faq/?mid=13100835
                </a>
              </p>
              <Title level={2}>3. SPOTON ACCOUNT</Title>
              <p>
                With a SpotOn account for GBP Services, Client authorizes SpotOn
                to manage business information on GBP & Google Ads for Client.
              </p>
              <p>
                Sign up for a SpotOn account for GBP Services on the GBP page in
                SpotOn Marketing:{" "}
                <a href="https://gmb-web.spoton.com">gmb-web.spoton.com</a>.
              </p>
              <p>
                Upon selecting the option “Create my account for me”, Client
                consents to SpotOn managing business information on GBP for
                Client.
              </p>
              <p>
                SpotOn will create or claim Client’s GBP location on Client’s
                behalf. SpotOn’s Gmail account (gmb@spoton.com) will be the
                primary owner of the location. SpotOn will not share passwords
                with Client. No Client Gmail account will be associated with the
                location. If Client chooses to be a secondary owner, SpotOn will
                grant secondary ownership access to Client upon the Client’s
                request.
              </p>
              <Title level={2}>4. GBP DATA</Title>
              <p>
                SpotOn will use Client’s GBP data: (a) to manage business
                information on GBP for Client; (b) as authorized by the GBP
                Terms; and (c) as documented in written instructions provided by
                Client and acknowledged in writing by SpotOn as constituting
                instructions for purposes of these GBP Terms, unless required to
                do otherwise by Applicable Laws; in such a case, SpotOn shall
                inform Client of the Applicable Laws, unless Applicable Laws
                prohibit such notice on important grounds of public interest.
                “Applicable Laws” means applicable national, federal, state, and
                local laws, rules, guidelines, court or government agency
                orders, and regulations.
              </p>
              <p>
                SpotOn will not Sell Client’s GBP or Google Ads data. “Sell”
                means selling, renting, releasing, disclosing, disseminating,
                making available, transferring, or otherwise communicating
                orally, in writing, or by electronic or other means, a data
                subject’s personal data for monetary or other valuable
                consideration.
              </p>
              <p>
                Google uses different sources of data, including user-generated
                content and other third-party data, to ensure that the most
                accurate information is published. SpotOn will consult with
                Client to verify data accuracy, and not take automated actions
                to discard suggested updates.
              </p>
              <Title id="disclosure" level={2}>
                5. REVIEWS
              </Title>
              <p>
                Once SpotOn claims or creates a GBP location for Client, Client
                will have access to a GBP dashboard to see customer reviews on
                its business. Client may respond to business reviews on Google.
              </p>
              <p>
                All responses to reviews must follow Google’s Review Content
                Policies:{" "}
                <a href="https://support.google.com/contributionpolicy/answer/7422880">
                  https://support.google.com/contributionpolicy/answer/7422880
                </a>
                .
              </p>
              <Title level={2}>6. INSIGHTS</Title>
              <p>
                Client will have access to a GBP dashboard to view insights such
                as how many people visited its website, viewed its posts, and
                searched for its business. The insights are pulled from the GBP
                API:{" "}
                <a href="https://developers.google.com/my-business">
                  https://developers.google.com/my-business
                </a>
                .
              </p>
              <Title level={2}>7. GBP & Google Ads ACCOUNTS OWNERSHIP</Title>
              <p>
                Client owns the GBP & Google Ads accounts, and has the right to
                determine SpotOn’s access rights (e.g. Co-Owner, Manager, or no
                access).
              </p>
              <p>
                Client may terminate SpotOn for GBP Services anytime with
                written notice. Within seven (7) business days of receiving
                notice from Client, SpotOn will (a) stop managing Client’s GBP
                accounts, (b) give Client exclusive control of the GBP accounts,
                (c) transfer primary ownership of Client’s Business Profile to
                Client, and (d) relinquish permissions to manage or otherwise
                change Client’s GBP accounts.
              </p>
              <Title level={2}>
                8. SUSPENSION AND TERMINATION OF THE SERVICE
              </Title>
              <p>
                SpotOn may suspend or terminate Client’s use of a SpotOn account
                for GBP & Google Ads Services, at any time, in its sole
                discretion, if SpotOn reasonably believes in good faith Client
                is in violation of the Merchant Terms, GBP Terms, or Applicable
                Laws.
              </p>

              <Title id="privacy" level={2}>
                9. GBP & Google Ads POLICIES
              </Title>
              <p>
                Client and SpotOn agree to comply with all applicable GBP
                policies:{" "}
                <a href="https://support.google.com/business/answer/7667250">
                  https://support.google.com/business/answer/7667250
                </a>
                .
              </p>
              <p>
                Client and SpotOn agree to comply with all applicable Google Ads
                policies:{" "}
                <u>
                  <a href="https://support.google.com/adspolicy/answer/6008942">
                    https://support.google.com/adspolicy/answer/6008942
                  </a>
                </u>
                .
              </p>
              <p>
                The Google Ads app’s use and transfer of information received
                from Google APIs to any other app will adhere to the{" "}
                <u>
                  <a href="https://developers.google.com/terms/api-services-user-data-policy">
                    Google API Services User Data Policy
                  </a>
                </u>
                , including the{" "}
                <u>
                  <a href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes">
                    Limited Use requirements
                  </a>
                </u>
                . We only use Google user data obtained via Restricted and
                Sensitive Scopes for the purposes of creating and managing
                Google Ads campaigns. For more information, please see the
                Google API Services User Data Policy.
              </p>
              <p>
                <u>
                  <a href="https://support.google.com/adspolicy/answer/9457109">
                    Advertiser Guide: Working with third parties
                  </a>
                </u>
              </p>

              <Title id="comments" level={2}>
                10. MANAGEMENT FEES
              </Title>
              <p>Google offers GBP & Google Ads as a free tool.</p>
              <p>
                SpotOn is not currently charging a separate fee to create or
                manage GBP accounts, and includes GBP & Google Ads Services as
                part of SpotOn Marketing. In the future, SpotOn may charge a
                management fee for the valuable services it provides to Client.
                SpotOn will give Client reasonable advance notice of changes in
                its management fees, and disclose its fees in Client invoices.
              </p>
              <Title level={2}>11. DISCLOSURE NOTICE</Title>
              <p>
                To learn what businesses can expect when working with SpotOn,
                read the `&quot;`Working with a third party`&quot;` disclosure
                notice:{" "}
                <a href="https://support.google.com/business/answer/7163406">
                  https://support.google.com/business/answer/7163406
                </a>
              </p>
              <Title level={2}>12. PUBLICITY</Title>
              <p>
                Use of GBP & Google Ads does not create, suggest, or imply a
                partnership with, sponsorship by, or endorsement by Google or
                SpotOn. Client will not make statements regarding Client’s use
                of GBP that suggests these types of relationships without prior
                written approval from Google or SpotOn.
              </p>
              <Title level={2}>13. INTERFERING WITH GOOGLE ACTIVITIES</Title>
              <p>
                Google may monitor and audit GBP & Google Ads activity to ensure
                compliance with all applicable Google policies and terms. Client
                agrees not to interfere with such monitoring or auditing and
                should not conceal from Google its GBP & Google Ads activity.
                Any interference will be considered a violation of Google
                policies.
              </p>
              <Title level={2}>14. DISCLAIMER OF WARRANTIES</Title>
              <p>
                SpotOn makes no warranties or representations as to the
                accuracy, completeness, reliability, usefulness,
                non-infringement, quality, originality, or truth of Client’s
                business information on GBP or GBP Services, regardless of
                whether SpotOn reviewed, monitored, or moderated such
                information or GBP Services. SpotOn has no obligation to review,
                monitor, or moderate Client’s business information or GBP
                Services.
              </p>
              <p>
                SPOTON MAKES NO (AND SPOTON SPECIFICALLY DISCLAIMS ALL)
                REPRESENTATIONS, WARRANTIES OR CONDITIONS OF ANY KIND, WHETHER
                EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, INCLUDING, WITHOUT
                LIMITATION, REPRESENTATIONS, WARRANTIES OR CONDITIONS: (A)
                ARISING OUT OF ANY COURSE OF PERFORMANCE, COURSE OF DEALING OR
                USAGE OF TRADE; (B) OF: (I) SATISFACTORY QUALITY; (II) FITNESS
                FOR A PARTICULAR PURPOSE; (III) NON-INFRINGEMENT; OR (IV)
                INTEROPERABILITY WITH THIRD-PARTY PRODUCTS OR SERVICES; AND (C)
                THAT SPOTON FOR GBP SERVICES WILL BE UNINTERRUPTED, ERROR-FREE,
                OR FREE OF HARM. SOME JURISDICTIONS DO NOT ALLOW THE ABOVE
                EXCLUSIONS. THE ABOVE EXCLUSIONS WILL NOT APPLY SOLELY TO THE
                EXTENT PROHIBITED BY LAW.
              </p>
              <p>
                IF CLIENT IS DISSATISFIED WITH SPOTON FOR GBP SERVICES OR THE
                GBP TERMS, CLIENT’S SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE
                USE OF SPOTON FOR GBP SERVICES.
              </p>
              <p>
                This Section 14 (DISCLAIMER OF WARRANTIES) survives expiration
                or termination of the GBP Terms.
              </p>
              <Title level={2}>15. LIMITATION OF LIABILITY</Title>
              <p>
                Client’s sole and exclusive remedy hereunder shall be to
                discontinue use of SpotOn for GBP & Google Ads Services. THE
                LIMITATIONS AND EXCLUSIONS APPLY IF THIS REMEDY DOES NOT FULLY
                COMPENSATE CLIENT FOR ANY LOSSES OR FAILS OF ITS ESSENTIAL
                PURPOSE.
              </p>
              <p>
                IN NO EVENT WILL SPOTON BE LIABLE FOR INDIRECT, INCIDENTAL,
                SPECIAL, PUNITIVE, COVER, LOSS OF PROFITS OR REVENUE, OR
                CONSEQUENTIAL DAMAGES (INCLUDING, WITHOUT LIMITATION, LOSS OF
                GOODWILL, LOSS OR USE OF DATA) HOWEVER CAUSED, WHETHER BASED IN
                CONTRACT, TORT, WARRANTY, NEGLIGENCE, OR OTHER LIABILITY THEORY,
                EVEN IF SPOTON HAS BEEN ADVISED AS TO THE POSSIBILITY OF SUCH
                DAMAGES. SOME JURISDICTIONS DO NOT ALLOW THE ABOVE EXCLUSIONS.
                THE ABOVE EXCLUSIONS WILL NOT APPLY SOLELY TO THE EXTENT
                PROHIBITED BY LAW.
              </p>
              <p>
                This Section 15 (LIMITATION OF LIABILITY) survives expiration or
                termination of the GBP Terms.
              </p>
              <Title level={2}>16. INDEMNIFICATION</Title>
              <p>
                Client will defend, indemnify, and hold harmless SpotOn, its
                Affiliates, and their officers, employees, independent
                contractors (“Representatives”), directors, successors, and
                assigns (“SpotOn Indemnified Parties”), against any third party
                claim, demand, lawsuit, dispute, or proceeding (“Claim”): (a)
                that Client’s, Client Affiliates’, Client Representatives’ use
                of SpotOn for GBP & Google Ads Services in breach of these GBP
                Terms, infringes or misappropriates a trademark, copyright,
                trade secret, or patent (to the extent such infringement or
                misappropriation is not the result of SpotOn’s actions); (b)
                relating to Client’s, Client Affiliates’, Client
                Representatives’ use of SpotOn for GBP Services in violation of
                the GBP Terms; (c) relating to Client’s business information; or
                (d) relating to the acts and omissions of Client Affiliates’ or
                Client Representatives’ use of SpotOn for GBP Services. Client
                will, with respect to any Claim against SpotOn Indemnified
                Parties that is subject to this Section 16 (Indemnification),
                indemnify SpotOn Indemnified Parties for damages, attorney fees,
                expert witness fees, and litigation costs finally awarded
                against SpotOn Indemnified Parties to such third party by a
                court of competent jurisdiction or agreed in settlement.
              </p>
              <p>
                This Section 16 (Indemnification) survives expiration or
                termination of the GBP Terms.
              </p>
              <Title level={2}>17. INTEGRATION</Title>
              <p>
                If we cannot resolve any complaint or dispute by
                negotiation,These GBP Terms: (a) constitute the entire agreement
                between the parties and supersedes all prior agreements or
                communications between the parties with regard to the subject
                matter; (b) may not be amended or modified except by a writing
                signed by each party; and (c) supersedes and controls over
                conflicting or additional terms and conditions of any purchase
                order, acknowledgement, confirmation, or other document Client
                issues.
              </p>
              <Row>
                <Col>
                  <Title id="contact" level={2}>
                    SpotOn’s contact information is:
                  </Title>
                  <Title level={4}>
                    Merchant contact information may be found on the Merchant’s
                    website and/or Facebook page, or by contacting SpotOn.
                  </Title>
                  <p>
                    SpotOn, Inc.
                    <br /> 100 California St, 9th Floor <br /> San Francisco, CA
                    94111 <br /> Phone:
                    <a href="tel:+18778144102">&nbsp; 877-814-4102</a>
                    <br /> Email:
                    <a href="mailto:support@spoton.com">
                      {" "}
                      &nbsp; support@spoton.com
                    </a>
                  </p>
                </Col>
              </Row>
            </div>
          </Col>
          <Col lg={8} sm={24}>
            <div className="aside">
              <Title level={2}>Related Documents</Title>
              <CtaSecondary
                target="/legal/consumer-privacy"
                ctaTitle="Consumer Privacy"
              />
              <CtaSecondary
                target="/legal/merchant-privacy"
                ctaTitle="Merchant Privacy"
              />
              <CtaSecondary
                target="/legal/merchant-terms"
                ctaTitle="Merchant Terms"
              />
              <CtaSecondary
                target="/payments-terms.pdf"
                ctaTitle="Payments Terms"
              />
              <CtaSecondary
                target="/legal/point-of-sale-terms"
                ctaTitle="Point-of-Sale Terms"
              />
              <CtaSecondary
                target="/legal/website-terms"
                ctaTitle="Website Terms"
              />
              <CtaSecondary
                target="/legal/reserve-terms"
                ctaTitle=" Reserve Terms"
              />
              <CtaSecondary
                target="/legal/teamwork-terms"
                ctaTitle="SpotOn Teamwork Terms"
              />
            </div>
          </Col>
        </Row>
      </main>

      <LegalStyles />
    </Layout>
  );
};

export default GBP;
